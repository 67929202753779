exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/Create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/Demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/Gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/Login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */)
}

